
import { reactive, toRefs } from "vue";
// import axios from 'axios';
// import store from '../store'
import firebase from "firebase";

export function useImager() {
    
    console.log("useFetcher")

    const state = reactive({
        loading: false,
        data: [],
        error: false
    })

    const fetchImageUrl = async function (val, r) {
        console.log("useFetcher", val, r)
        // state.loading = true
        // return await axios.get
        const storage = firebase.storage();
        const storageRef = storage.ref()
        // const pathReference = storage.ref('catalogue/otel/16ded9ccd4d-pers.png');
        // const pathReference = storage.ref(val);
        // console.log("pathReference", pathReference)
        
        let u = ""
        await storageRef.child(val).getDownloadURL().then(function(url) {
            // inserted into an <img> element:
            // var card = document.getElementsByClassName('Card');
            var img = document.getElementById(r)
            img.src = url;
            u = url
            console.log("useFetcher image", url)
            return u;
        }).catch(function(error) {
        // Handle any errors
            console.log("error", error)
            console.log("useFetcher image", error)
            u = ""
            return u;
        });
        return u;
        
    }
    //  const fetchData = async function () {
    //     // loading.value = true
    //     // data.value = ["hello","buy"]
    //     // error.value = false;
    //     // loading.value = false
    //     state.loading = true
    //     state.data = ["hello","buy"]
    //     return await axios.get('https://products3dplanner.firebaseio.com/1/collections/0/familles/0/groupes.json').then(res => {
    //         // console.log("_=========__", res)
    //         console.log("res", res)
    //         // loading.value = false
    //         state.data = res.data
    //         // console.log("data.value", data.value)
    //         // error.value = true
    //         state.error = false
    //         state.loading = false

    //     })
    // }

    return {...toRefs(state), fetchImageUrl}

}