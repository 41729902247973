// import firebase from "firebase";
import axios from 'axios';
// import { mapActions } from 'vuex'
import store from '../store'
// import { mapActions } from 'vuex'
import firebase from "firebase";


export default class ProductsList {
    
    getProducts() {    
        
        // function click_item (val) {
        //     //console.log("click", val)
        // }

        // function generateStep (val) {
        //     //console.log("click", val)

        //     let step = [];

        //     step.push({label: 'BOUTIQUE'})

        //     return step
        // }

        var products_list = { 
            "data": [
                {
                   label: 'room 1',
                }
            ]
        };

        

        console.log("mounted ", firebase.auth().currentUser );
        // console.log("mounted ", store.getters.getProject );
        // var starCountRef = firebase.database().ref('posts/' + postId + '/starCount');
        return axios.get('https://'+store.getters.getProject+'.firebaseio.com/.json').then(res => {
        // return firebase.database().ref().then(res => {
            
            this.list = res
            // console.log("res", res.data)
            store.commit('setProducts', res.data);
            
            // let step = [];
            // //console.log("res", res)
            let i0 = 0;
            let n0 = []

            res.data.forEach(function(childSnapshot) {
                
                var current_step = [];
                var key = i0;
                
                var name = childSnapshot.nom_fr;
                
                // n0 = [{label: name}]
                // var key_name = [{label: name}];

                // step.push({label: 'BOUTIQUE'})
                // n0 = name
                
                current_step.push({label: name})

                products_list.data[i0] = {
                    key: key,
                    label: name,
                    // step: [{label: name}],
                    // step: n0,
                    command: (e) => {
                        // ////console.log("click", e.item.step)
                        // click_item(generateStep(e.item.key));
                        store.commit('step', e.item.key);
                        //console.log("closeProductCard")
                        store.dispatch('closeProductDetail');
                    },
                    items: [],
                }

                

                var i1;
                var node_use = childSnapshot.collections
                if ( node_use.length > 0 ) {

                    

                    for ( i1 = 0; i1 < node_use.length; i1++ ) {

                        // current_step.push({label: node_use[i1].nom_fr})
                        //console.log("key_name", n0)
                        key = i0+':'+i1;
                        // n0.push({label: 'BOUTIQUE'})
                        // key_name.push({label: node_use[i1].nom_fr})
                        // = i0+':'+i1;
                        name = node_use[i1].nom_fr;
                        products_list.data[i0].items[i1] = {
                            key: key,
                            label: name,
                            // step: [{label: name}],
                            step: n0,
                            // step: current_step.push({label: node_use[i1].nom_fr}),
                            command: (e) => {
                                //console.log("click", e.item.key)
                                store.commit('step', e.item.key);
                                store.dispatch('closeProductDetail');
                            },
                            items: [],
                        }

                        var i2;
                        
                        var node_use_1 = node_use[i1].familles
                        
                        if ( node_use_1 != undefined && node_use_1.length > 0 ) {
                            
                            for ( i2 = 0; i2 < node_use_1.length; i2++ ) {

                                key = i0+':'+i1+':'+i2;
                                name = node_use_1[i2].nom_fr;
                                products_list.data[i0].items[i1].items[i2] = {
                                    key: key,
                                    label: name,
                                    command: (e) => {
                                        //console.log("click", e.item.key)
                                        store.commit('step', e.item.key);
                                        store.dispatch('closeProductDetail');
                                    },
                                    items: [],
                                }


                                var i3;
                                var node_use_2 = node_use[i1].familles[i2].familles2
                                
                                if ( node_use_2 != undefined && node_use_2.length > 0 ) {
                                    for ( i3 = 0; i3 < node_use_2.length; i3++ ) {
                                        
                                        key = i0+':'+i1+':'+i2+':'+i3;
                                        name = node_use_2[i3].nom_fr;
                                        products_list.data[i0].items[i1].items[i2].items[i3] = {
                                            key: key,
                                            label: name,
                                            command: (e) => {
                                                //console.log("click", node_use_2)
                                                store.commit('step', e.item.key);
                                                store.dispatch('closeProductDetail');
                                            },
                                            items: [],
                                        }


                                        var i4;
                                        var node_use_3 = node_use[i1].familles[i2].familles2[i3].familles3
                                        
                                        if ( node_use_3 != undefined && node_use_3.length > 0 ) {
                                            for ( i3 = 0; i3 < node_use_3.length; i3++ ) {
                                                
                                                key = i0+':'+i1+':'+i2+':'+i3+':'+i4;
                                                name = node_use_3[i4].nom_fr;
                                                products_list.data[i0].items[i1].items[i2].items[i3].items[i4] = {
                                                    key: key,
                                                    label: name,
                                                    command: (e) => {
                                                        //console.log("click", e.item.key)
                                                        store.commit('step', e.item.key);
                                                        store.dispatch('closeProductDetail');
                                                    },
                                                    items: [],
                                                }
                                            }
                                        }

                                        i4++;
                                        
                                    }
                                }

                                i3++;

                            }
                        }

                    }
                }

                i0++;
            });

            
            
            return products_list;
        });
        
    }
    
}