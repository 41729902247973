
import { reactive, toRefs } from "vue";
// import axios from 'axios';
import store from '../store'
import firebase from "firebase";

export function useModifier() {
    
    console.log("useModifier")

    const state = reactive({
        loading: false,
        data: [],
        error: false
    })

    // const fetchImageUrl = async function (val, r) {
    //     console.log("useFetcher", val, r)
    //     // state.loading = true
    //     // return await axios.get
    //     const storage = firebase.storage();
    //     const storageRef = storage.ref()
    //     // const pathReference = storage.ref('catalogue/otel/16ded9ccd4d-pers.png');
    //     // const pathReference = storage.ref(val);
    //     // console.log("pathReference", pathReference)
        
    //     let u = ""
    //     await storageRef.child(val).getDownloadURL().then(function(url) {
    //         // inserted into an <img> element:
    //         // var card = document.getElementsByClassName('Card');
    //         var img = document.getElementById(r)
    //         img.src = url;
    //         u = url
    //         console.log("useFetcher image", url)
    //         return u;
    //     }).catch(function(error) {
    //     // Handle any errors
    //         console.log("error", error)
    //         console.log("useFetcher image", error)
    //         u = ""
    //         return u;
    //     });
    //     return u;
        
    // }




    //  const fetchData = async function () {
    //     // loading.value = true
    //     // data.value = ["hello","buy"]
    //     // error.value = false;
    //     // loading.value = false
    //     state.loading = true
    //     state.data = ["hello","buy"]
    //     return await axios.get('https://products3dplanner.firebaseio.com/1/collections/0/familles/0/groupes.json').then(res => {
    //         // console.log("_=========__", res)
    //         console.log("res", res)
    //         // loading.value = false
    //         state.data = res.data
    //         // console.log("data.value", data.value)
    //         // error.value = true
    //         state.error = false
    //         state.loading = false

    //     })
    // }


     const updateData = async function (val) {
        // loading.value = true
        // data.value = ["hello","buy"]
        // error.value = false;
        // loading.value = false
        state.loading = true
        state.data = []


        
        console.log("updateData", val)
        var step_tab = []
        step_tab = store.getters.getCurrentStep
        
        // for ( var i = 0; i < step_tab.length; i++ ) {
        // console.log("step" )
        let json_path = ''// 'https://products3dplanner.firebaseio.com/'
        var i = 0
        for ( i = 0; i < step_tab.length; i++ ) {
            // console.log("getCurrentStep", json_path, step_tab[i])
            if ( i == 0 ) {
                json_path += step_tab[i] + '/'
            }else if ( i == 1 ) {
                json_path += 'collections/' + step_tab[i] + '/'
            }else if ( i == 2 ) {
                json_path += 'familles/' + step_tab[i] + '/'
            }else if ( i == 3 ) {
                json_path += 'familles2/' + step_tab[i] + '/'
            }else if ( i == 3 ) {
                json_path += 'familles3/' + step_tab[i] + '/'
            }
            
        }
        json_path += 'groupes/' + store.getters.getCurrentProduct //+ '.json'
        console.log("json_path", json_path)

        var db = firebase.database()

        
    
        db.ref(json_path).update(val)
        

        
        // return await axios.get(json_path).then(res => {
        //     // console.log("_=========__", res)
        //     console.log("res", res)
        //     // loading.value = false
        //     state.data = res.data

        //     var database = firebase.database();
        //     console.log("database", database)
            
        //     // modelStore.getters.get_univers
            
        //     // let firebaseApp = firebase.initializeApp(fireConfig);
        //     // // Get firebase data base
        //     // var db = firebaseApp.database()
        //     // db.ref(modelStore.getters.get_univers+'/collections/'+modelStore.getters.get_collection+'/familles/'+modelStore.getters.get_famille+'/familles2/'+modelStore.getters.get_famille2+'/groupes/'+pos_el).update({
        //     //     visibilitebiblio: this.value
        //     // })

        //     // console.log("data.value", data.value)
        //     // error.value = true
        //     state.error = false
        //     state.loading = false

        // })
    }


    return {...toRefs(state), updateData}

}