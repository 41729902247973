export const increment = state => {
    state.count++
    state.history.push('increment')
}

export const decrement = state => {
    state.count--
    state.history.push('decrement')
}


export const setProducts = function (state, products) {
    console.log("products", products)
    state.products = products
}
export const getProductDetail = function (state, productinfo) {
    console.log("showProductDetail", productinfo)
    state.productdetail = productinfo
}

export const toggleProductDetail = function (state) {
    console.log("showProductDetail", state)
    // state.products = products
    state.displayProducts = !state.displayProducts
}

export const closeProductDetail = function (state) {
    console.log("closeProductDetail")
    // state.products = products
    state.displayProducts = true
}

export const step = function (state, val) {
    // state.step_num = val;
    var final_val = []
    var tab = val.toString().split(':')
    state.currentStep = tab;
    console.log("state.curentObject", state.currentStep)
    var open = false
    // state.step = tab;
    for ( var i = 0; i < tab.length; i++ ) {
        var ori;
        if ( i == 0 ) {
            ori = state.products[tab[i]]
            final_val.push({label: ori.nom_fr})
            
            if ( ori.groupes != undefined ) {
                // console.log("ori.nom_fr", ori.groupes )
                state.groupes = ori.groupes;
                open = true
            }
        }else if ( i == 1 ) {
            ori = ori.collections[tab[i]]
            final_val.push({label: ori.nom_fr})
            // console.log("ori.nom_fr", ori.groupes )
            if ( ori.groupes != undefined ) {
                // console.log("ori.nom_fr", ori.groupes )
                state.groupes = ori.groupes;
                open = true
            }
        }else if ( i == 2 ) {
            ori = ori.familles[tab[i]]
            final_val.push({label: ori.nom_fr})
            // console.log("ori.nom_fr", ori.groupes )
            if ( ori.groupes != undefined ) {
                // console.log("ori.nom_fr", ori.groupes )
                state.groupes = ori.groupes;
                open = true
            }
        }else if ( i == 3 ) {
            ori = ori.familles2[tab[i]]
            final_val.push({label: ori.nom_fr})
            // console.log("ori.nom_fr", ori.groupes )
            if ( ori.groupes != undefined ) {
                // console.log("ori.nom_fr", ori.groupes )
                state.groupes = ori.groupes;
                open = true
            }
        }else if ( i == 4 ) {
            ori = ori.familles3[tab[i]]
            final_val.push({label: ori.nom_fr})
            if ( ori.groupes != undefined ) {
                // console.log("ori.nom_fr", ori.groupes )
                state.groupes = ori.groupes;
                open = true
            }
        }
    }
    if ( open ) {
        state.step = final_val
    }
    // state.step = val// final_val
}

export const setProductId = function (state, val) {
    state.currentProduct = val
}

export const SETPROJECT = function (state, val) {
    // state.currentProduct = val
    console.log("setProject", val)
    state.currentProject = val
}