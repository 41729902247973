import { ref, reactive, toRefs } from 'vue'
import firebase from '../Firebase'

export function useData () {
    const n = ref(0);
    // console.log("n", n)
    const increment = function() {
        n.value++
    }
    return [
        n,
        increment
    ]
}

export function useFireCon () {
    // const n = ref(0);
    const state = reactive({
        n: 0
    })
    // console.log("n", n)
    const pushImage = function(data,type,nom) {
        console.log("increment", type,nom)
        state.n++

        let img1 = null
        // var uploadValue = 0

        // const storageRef=firebase.storage().ref(data.name).child('/catalogue/otel/').put(data)
        const storageRef=firebase.storage().ref('/catalogue/otel/'+data.name).put(data)

        storageRef.on(`state_changed`,snapshot=>{
            // this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
            console.log("up", (snapshot.bytesTransferred/snapshot.totalBytes)*100)
        }, error=>{console.log(error.message)},
        ()=>{
            // uploadValue=100;
            storageRef.snapshot.ref.getDownloadURL().then((url)=>{
                img1 =url;
                console.log(img1)
            });
        }   
        );
        
        
    }
    // return [
    //     n,
    //     increment
    // ]
    return {...toRefs(state), pushImage}
}