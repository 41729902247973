export const increment = ({ commit }) => {
    commit('increment')
  }
  export const decrement = ({ commit }) => {
    commit('decrement')
  }
  
  export const incrementIfOdd = ({ commit, state }) => {
    if ((state.count + 1) % 2 === 0) {
      commit('increment')
    }
  }
  
  export const incrementAsync = ({ commit }) => {
    setTimeout(() => {
      commit('increment')
    }, 1000)
  }

  export const step = ({ commit } ) => {
    //console.log("click step !")
    commit('step')
    // fermer fiche produit
  }

  // export const closeProductCard = ({ commit } ) => {
  //   //console.log("close productCard ")
  //   commit('closeProductDetail')
  // }
  
    
  // export const showProductDetail = ({ commit }, {info}) => {
  export const showProductDetail = ({ commit }, info) => {
    console.log("info", info)
    commit('toggleProductDetail')
    commit('getProductDetail', info[0])
    commit('setProductId', Number(info[1])-1)
  }

  export const closeProductDetail = ({ commit, getters }) => {
    //console.log ( getters.getDisplayProducts )//.getter.getDisplayProducts)
    if(!getters.getDisplayProducts){
      commit('closeProductDetail')
    }
  }


  export const setProject = ({ commit }, val ) => {
    console.log("setProject", val)
    commit('SETPROJECT', val)
    // fermer fiche produit
  }