<template>
  <!-- <div id="app"> -->
    
    <login v-if="showLogin"/>

    <Header/>
    
    <!-- <div class="p-d-flex p-p-3 card" id="header">
      <Button type="Button" icon="pi pi-check" class="p-mr-2" />
      <Button type="Button" icon="pi pi-trash" class="p-button-danger"/>
      <Button type="Button" icon="pi pi-search" class="p-ml-auto p-button-help"/>
    </div> -->

    <!-- l'arborescence  -->
    <div class="p-grid" v-if="show">
      
      <div class="p-col-4 tree">
        <Tree/>
      </div>

      <div class="p-col-8">
        
        <Products />
        <!-- <HelloWorld msg="Welcome to Your PrimeVue App"/> -->
        <!-- <img alt="Vue logo" src="./assets/primevue-logo.png">
        <HelloWorld msg="Welcome to Your PrimeVue App"/>
        <form @submit.prevent="greet">
          <InputText type="text" v-model="text"/>
          <Button type="submit" label="Submit"/>
          <h3>{{message}}</h3>
        </form> -->
        
      </div>

    </div>


    <Toast/>

    

  <!-- </div> -->
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import Header from './views/Header.vue'
import Tree from './views/Tree.vue';
import Products from './views/Products.vue';
import Login from './views/Login.vue';
import firebase from "firebase";

export default {
    data() {
        return {
            message: null,
            text: null,
            show: true,
            showLogin: false,
        }
    },
    methods: {
      
        greet() {
            this.$toast.add({severity: 'info', summary: 'Hello '  + this.text});
            this.message = 'Hello ' + this.text;
        }
        
        // firebase.auth().onIdTokenChanged(function(user) {
        //     if (user) {
                
        //     }
        // });

    },
    created() {
      // console.log("auth ", firebase.auth().currentUser );
      // if ( firebase.auth() != null ) {
      //   this.showLogin = true;
      // }
      let ref_value = ''
      // let path_value = ''
      let uri = window.location.href.split('?');
      console.log("uri", uri)
      if (uri.length == 2)
      {
        let vars = uri[1].split('&');
        let getVars = {};
        let tmp = '';
        vars.forEach(function(v){
          tmp = v.split('=');
          console.log("tmp", tmp)
          if(tmp.length == 2)
          getVars[tmp[0]] = tmp[1];
        });
        
        // do 
        ref_value=getVars.client;
        // path_value=getVars.path;
        console.log("ref_value", ref_value)
      }
      
      firebase.auth().onAuthStateChanged(user =>  {
        
        if (user) {

          console.log("auth ", firebase.auth().currentUser );
          var userf = firebase.auth().currentUser;
          console.log("auth token : ", firebase.auth().currentUser)

          // firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(function(idToken) {
          //   // Send token to your backend via HTTPS
          //   // ...
          //   console.log("idToken", idToken)
          //   document.cookies.set('token', idToken, {
          //       domain: window.location.hostname,
          //       expire: 1 / 24, // One hour
          //       path: '/',
          //       secure: true // If served over HTTPS
          //   });
          // }).catch(function(error) {
          //   // Handle error
          //   console.log("error", error)
          // });

          // if ( userf. )
          // userf.name = "Sebastien";
          // userf.updateProfile({
          //   displayName: "admin",
          //   role: "admin",
          //   // photoURL: "https://example.com/jane-q-user/profile.jpg"
          // }).then(function() {
          //   // Update successful.
          // }).catch(function(error) {
          //   // An error happened.
          //   console.log("error", error)
          // });
          console.log("userf.name", userf)
          this.showLogin = false;
        }else{
          this.showLogin = true;
        }
      })

// 
        // var fshow = this.show
        // this.show = firebase.auth().onIdTokenChanged(function(user) {
        //     if (user) {
        //     //         // User is signed in or toke
        //       console.log("get user id")
        //       console.log("created", firebase.auth().currentUser);
        //       return true;

        //     }else{
        //       return true;
        //     }
        // })

    },
    components: {
      Header,
      Tree,
      Products,
      Login
    }
}
</script>


<style>
body {
  margin: 0px;
}
</style>
<style scoped>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 60px;
  border: 0px;
}


body #app .p-button {
  margin-left: .2em;
  
}


form {
  margin-top: 2em;
}
.p-col {
  background-color: grey;
  margin: 1px;
  padding: 1px;
}
.p-col-4, .p-col-8 {
  /* padding: 0.5rem; */
  padding-left: 0rem;
  padding-right: 0rem;
}
.tree {
  max-width: 350px;
  min-width: 200px;
} 

</style>