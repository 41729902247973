import { createStore } from 'vuex'
import * as getters from './getters'
import * as actions from './actions'
import * as mutations from './mutations'

const state = {
  count: 0,
  history: [],
  currentProject: 'my3dplanner',
  step: [],
  step_id: [],
  groupes: [],
  products: [],
  displayProducts: true,
  productdetail: [],
  currentStep: [],
  currentProduct: -1
  // step_old: [
  //           {label: 'BOUTIQUE'},
  //           {label: 'MEUBLE'},
  //       ]
}

const store = createStore({
  state,
  getters,
  actions,
  mutations
})

if (module.hot) {
  module.hot.accept([
    './getters',
    './actions',
    './mutations'
  ], () => {
    store.hotUpdate({
      getters: require('./getters'),
      actions: require('./actions'),
      mutations: require('./mutations')
    })
  })
}

export default store