<template>
    
    <PanelMenu :model="products" />
    
</template>

<script>

import ProductsList from '../services/ProductsList';
import { mapGetters, mapActions } from 'vuex'
import firebase from "firebase";

export default {
  name: 'Tree',
  data() {
        return {
            products: null,
            categories: [
                {
                   label: 'File',
                   icon:'pi pi-fw pi-file',
                   items: [
                      {
                         label: 'New',
                         icon:'pi pi-fw pi-plus',
                         items: [
                            {
                               label: 'Bookmark',
                               icon:'pi pi-fw pi-bookmark'
                            },
                            {
                               label: 'Video',
                               icon:'pi pi-fw pi-video'
                            }
                         ]
                      },
                      {
                         label: 'Delete',
                         icon:'pi pi-fw pi-trash'
                      },
                      {
                         label: 'Export',
                         icon:'pi pi-fw pi-external-link'
                      }
                   ]
                },
                {
                   label: 'Edit',
                   icon:'pi pi-fw pi-pencil',
                   items: [
                      {
                         label: 'Left',
                         icon:'pi pi-fw pi-align-left'
                      },
                      {
                         label: 'Right',
                         icon:'pi pi-fw pi-align-right'
                      },
                      {
                         label: 'Center',
                         icon:'pi pi-fw pi-align-center'
                      },
                      {
                         label: 'Justify',
                         icon:'pi pi-fw pi-align-justify'
                      }
                   ]
                },
                {
                   label:'La pièce',
                   items:[
                      {
                         label:'Dessiner',
                         icon:'pi pi-fw pi-user-plus',

                      },
                      {
                         label:'Portes',
                         icon:'pi pi-fw pi-user-minus',

                      }
                   ]
                },
                {
                   label: 'Les meubles',
                   icon:'pi pi-fw pi-calendar',
                   items: [
                      {
                         label: 'Cuisine',
                         icon:'pi pi-fw pi-pencil',
                         items: [
                            {
                               label: 'Save',
                               icon:'pi pi-fw pi-calendar-plus'
                            },
                            {
                               label: 'Delete',
                               icon:'pi pi-fw pi-calendar-minus'
                            }
                         ]
                      },
                      {
                         label: 'Archieve',
                         icon:'pi pi-fw pi-calendar-times',
                         items: [
                            {
                               label: 'Remove',
                               icon:'pi pi-fw pi-calendar-minus'
                            }
                         ]
                      }
                   ]
                }
             ]
        }
    },
   productsList: null,
   created() {
      // firebase.auth().onIdTokenChanged(function(user) {
      //       console.log("mounted ", user)
      //       if (user) {
               // this.productsList = new ProductsList();
            
      //       }
      // });

      this.productsList = new ProductsList();
   },
   mounted() {
       
       var ff = this.productsList
      //  var ff2 = this.products
      var t = this;
       firebase.auth().onIdTokenChanged(function(user) {
            console.log("mounted:: ", user)
            if (user) {
               
               // console.log("mounted ", user)
                  // this.productsList.getProducts().then(data => {
                  // this.products = data.data
               // });
               // this.productsList = new ProductsList();
               console.log("t", t)
               // var ff3 = ff2
               ff.getProducts().then(data => {
                  console.log("mounted--> ", data.data)
                  t.products = data.data
               });

            }
        });


      // this.productsList.getProducts().then(data => {
      //    this.products = data.data
      // });
        
   },
   computed: mapGetters([
      //   'count',
      //   'recentHistory',
        'step'
    ]),
    methods: mapActions([
      //   'increment',
      //   'decrement',
      //   'incrementIfOdd',
      //   'incrementAsync'
    ])
}
</script>