import { createApp } from 'vue';
import store from './store'
import App from './App.vue';

import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import TabMenu from 'primevue/tabmenu';
import Menubar from 'primevue/menubar';
// import Panel from 'primevue/panel';
import PanelMenu from 'primevue/panelmenu';
import DataView from 'primevue/dataview';
import Breadcrumb from 'primevue/breadcrumb';
import Card from 'primevue/card';
import Dialog from 'primevue/dialog';
// import InputText from 'primevue/inputtext';
import FileUpload from 'primevue/fileupload';

import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import firebase from './Firebase.js';

// let uri = window.location.href.split('?');
// console.log("uri", uri)

// // Get variable parsed
// let ref_value = '';
// // let path_value = '';

// let uri = window.location.href.split('?');
// if (uri.length == 2)
// {
//   let vars = uri[1].split('&');
//   let getVars = {};
//   let tmp = '';
//   vars.forEach(function(v){
//     tmp = v.split('=');
//     if(tmp.length == 2)
//     getVars[tmp[0]] = tmp[1];
//   });
  
//   // do 
//   ref_value=getVars.ref;
// //   path_value=getVars.path;
//   console.log("ref_value", ref_value)
//   if ( ref_value == "OTEL" ) {
//     import firebase from './Firebase.js';
//     // const { firebase: firebase } = import('./Firebase');
//   }else if ( ref_value == "OTEL" ) {
//   }else{  
//   }
//   // const { default: firebase } = import('firebase_dav');

// }


  

const app = createApp(App);

app.use(store)
app.use(ToastService);
app.use(firebase)

app.component('InputText', InputText);
app.component('Button', Button);
app.component('Toast', Toast);
app.component('TabMenu', TabMenu);
app.component('PanelMenu', PanelMenu);
app.component('Menubar', Menubar);
app.component('DataView', DataView);
app.component('Breadcrumb', Breadcrumb);
app.component('Card', Card);
app.component('Dialog', Dialog);
// app.component('InputText', InputText);
app.component('FileUpload', FileUpload);

app.mount('#app')
