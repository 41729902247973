import firebase from 'firebase';
import store from './store'
// const settings = {timestampsInSnapshots: true};



// Recuperer le client
let ref_value;
let uri = window.location.href.split('?');
let config = {};

if (uri.length == 2)
{
  let vars = uri[1].split('&');
  let getVars = {};
  let tmp = '';
  vars.forEach(function(v){
    tmp = v.split('=');
    if(tmp.length == 2)
    getVars[tmp[0]] = tmp[1];
  });

  

  // charger firebase en focntion du client
  ref_value=getVars.client;
  
  if ( ref_value == "OTEL" ) {
    ref_value = "products3dplanner"
    config = {
        apiKey: "AIzaSyBtQCKNSqTXRYRfTL30UpNmGbgGgLeWD_s",
        authDomain: "products3dplanner.firebaseapp.com",
        databaseURL: "https://products3dplanner.firebaseio.com",
        projectId: "products3dplanner",
        storageBucket: "products3dplanner.appspot.com",
        messagingSenderId: "145900215214",
        appId: "1:145900215214:web:7f657237295f4c9da6463f"
    };
  }else if ( ref_value == "OTEL" ) {
    ref_value = "products3dplanner"
    config = {
        apiKey: "AIzaSyBtQCKNSqTXRYRfTL30UpNmGbgGgLeWD_s",
        authDomain: "products3dplanner.firebaseapp.com",
        databaseURL: "https://products3dplanner.firebaseio.com",
        projectId: "products3dplanner",
        storageBucket: "products3dplanner.appspot.com",
        messagingSenderId: "145900215214",
        appId: "1:145900215214:web:7f657237295f4c9da6463f"
    };
  }else if ( ref_value == "MY3DPLANNER" ) { 
    ref_value = "my3dplanner"
    config = {
        apiKey: "AIzaSyCrDeZ0I70Vlt2wowXidze1ziLi2f_jQ5c",
        authDomain: "my3dplanner.firebaseapp.com",
        databaseURL: "https://my3dplanner.firebaseio.com",
        storageBucket: "my3dplanner.appspot.com",
        messagingSenderId: "263711099911",
        projectId: "my3dplanner",
    };
  }else{
    ref_value = "products3dplanner"
    config = {
        apiKey: "AIzaSyBtQCKNSqTXRYRfTL30UpNmGbgGgLeWD_s",
        authDomain: "products3dplanner.firebaseapp.com",
        databaseURL: "https://products3dplanner.firebaseio.com",
        projectId: "products3dplanner",
        storageBucket: "products3dplanner.appspot.com",
        messagingSenderId: "145900215214",
        appId: "1:145900215214:web:7f657237295f4c9da6463f"
    };
  }
  // const { default: firebase } = import('firebase_dav');
  store.dispatch('setProject', ref_value);
}else{
  ref_value = "products3dplanner"
  config = {
      apiKey: "AIzaSyBtQCKNSqTXRYRfTL30UpNmGbgGgLeWD_s",
      authDomain: "products3dplanner.firebaseapp.com",
      databaseURL: "https://products3dplanner.firebaseio.com",
      projectId: "products3dplanner",
      storageBucket: "products3dplanner.appspot.com",
      messagingSenderId: "145900215214",
      appId: "1:145900215214:web:7f657237295f4c9da6463f"
  };
  store.dispatch('setProject', ref_value);
}


// const config = {
//     apiKey: "AIzaSyCrDeZ0I70Vlt2wowXidze1ziLi2f_jQ5c",
//     authDomain: "my3dplanner.firebaseapp.com",
//     databaseURL: "https://my3dplanner.firebaseio.com",
//     storageBucket: "my3dplanner.appspot.com",
//     messagingSenderId: "263711099911",
//     projectId: "my3dplanner",
// };

firebase.initializeApp(config);

// firebase.firestore().settings(settings);
firebase.firestore()


// firebase.getCurrentUser = () => {
//   return new Promise((resolve, reject) => {
//       const unsubscribe = firebase.auth().onAuthStateChanged(user => {
//           unsubscribe();
//           resolve(user);
//       }, reject);
//   })
// };

export default firebase;