<template>
<br>
<Button label="< retour" @click="close()"/>
<!-- <h2>Détail</h2> -->
  <div id="productCard"> 
    
    <!-- <img class="vignette" :id="'img'+value.ref" v-bind="getImage(chemin + value.icone, 'img'+value.ref)" width="250px"> -->
    <br>
    <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-4">
            <div class="p-text-italic">
                visibilite
            </div>
            <div class="p-inputgroup">
                <InputText id="visibilitebiblio" type="text" v-model="value.visibilitebiblio" @change="changeData"/>
            </div>
        </div>

    </div>
    <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-4">
            <div class="p-text-italic">
                Pers
            </div>
            <div class="vignette">
                <img class="image" :id="'img'+value.ref" v-bind="url" width="100%">
            </div>
        </div>
        <div class="p-col-12 p-md-4">
            <div class="p-text-italic">
                Top
            </div>
            <div class="vignette">
                <img class="image" :id="'imgTop'+value.ref" v-bind="url1" width="100%">
            </div>
        </div>
    </div>
    
    <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-4">
            <div class="p-text-italic">
                Fichier pers
            </div>
            <div class="p-inputgroup">
                <InputText id="icone" type="text" v-model="value.icone" />
            </div>
        </div>
        <div class="p-col-12 p-md-4">
            <div class="p-text-italic">
                Actions
            </div>
            <div class="p-inputgroup">
                
                <Button @click="clickUploadImage" label="Upload" style="margin-right:2px">Upload</Button>
                <input type="file" id="inputPers" style="display:none;" @change="uploadImage" accept="image/*" > 
                <Button label="Delete" style="margin-right:2px">Delete</Button>
            </div>
        </div>
    </div>
    <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-4">
            <div class="p-text-italic">
                Fichier 3d
            </div>
            <div class="p-inputgroup">
                <InputText id="h" type="text" v-model="value.image3d" />
            </div>
        </div>
        <div class="p-col-12 p-md-4">
            <div class="p-text-italic">
                Actions
            </div>
            <div class="p-inputgroup">
                <Button @click="openViewer(value.ref)" label="View" style="margin-right:2px">View</Button>
                <Button @click="clickUploadImage" label="Upload" style="margin-right:2px">Upload</Button>
                <input type="file" id="inputPers" style="display:none;" @change="uploadImage" accept="image/*" > 
                <Button label="Find" style="margin-right:2px">Find</Button>
                <Button label="Delete" style="margin-right:2px">Delete</Button>
            </div>
        </div>

        
    </div>
    <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-4">
            <div class="p-text-italic">
                Nom
            </div>
            <div class="p-inputgroup">
                <InputText id="nom_fr" type="text" v-model="value.nom_fr" />
            </div>
        </div>

        <div class="p-col-12 p-md-4">
            <div class="p-text-italic">
                Ref
            </div>
            <div class="p-inputgroup" >
                <InputText id="ref" type="text" v-model="value.ref" disabled/>
            </div>
        </div>

        <div class="p-col-12 p-md-4">
            <div class="p-text-italic">
                Ref_devis
            </div>
            <div class="p-inputgroup">
                <InputText id="ref_devis" type="text" v-model="value.ref_devis" />
            </div>
        </div>
    </div>
    <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-4">
            <div class="p-text-italic">
                l
            </div>
            <div class="p-inputgroup">
                <InputText id="l" type="number" v-model="value.l" @change="changeData"/>
            </div>
        </div>

        <div class="p-col-12 p-md-4">
            <div class="p-text-italic">
                h
            </div>
            <div class="p-inputgroup">
                <InputText id="h" type="number" v-model="value.h" @change="changeData"/>
            </div>
        </div>

        <div class="p-col-12 p-md-4">
            <div class="p-text-italic">
                p
            </div>
            <div class="p-inputgroup">
                <InputText id="p" type="number" v-model="value.p" @change="changeData"/>
            </div>
        </div>
    </div>
    <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-4">
            <div class="p-text-italic">
                scalel
            </div>
            <div class="p-inputgroup">
                <InputText id="scalel" type="number" v-model="value.scalel" @change="changeData"/>
            </div>
        </div>

        <div class="p-col-12 p-md-4">
            <div class="p-text-italic">
                scaleh
            </div>
            <div class="p-inputgroup">
                <InputText id="scaleh" type="number" v-model="value.scaleh" @change="changeData"/>
            </div>
        </div>

        <div class="p-col-12 p-md-4">
            <div class="p-text-italic">
                scalep
            </div>
            <div class="p-inputgroup">
                <InputText id="scalep" type="number" v-model="value.scalep" @change="changeData"/>
            </div>
        </div>
    </div>
    <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-4">
            <div class="p-text-italic">
                x
            </div>
            <div class="p-inputgroup">
                <InputText id="x" type="number" v-model="value.x" @change="changeData"/>
            </div>
        </div>

        <div class="p-col-12 p-md-4">
            <div class="p-text-italic">
                y
            </div>
            <div class="p-inputgroup">
                <InputText id="y" type="number" v-model="value.y" @change="changeData"/>
            </div>
        </div>

        <div class="p-col-12 p-md-4">
            <div class="p-text-italic">
                z
            </div>
            <div class="p-inputgroup">
                <InputText id="z" type="number" v-model="value.z" @change="changeData"/>
            </div>
        </div>
    </div>
    <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-4">
            <div class="p-text-italic">
                dateclo
            </div>
            <div class="p-inputgroup">
                <InputText id="dateclo" type="text" v-model="value.dateclo" @change="changeData"/>
            </div>
        </div>

        <div class="p-col-12 p-md-4">
            <div class="p-text-italic">
                datemaj
            </div>
            <div class="p-inputgroup">
                <InputText id="datemaj" type="text" v-model="value.datemaj" @change="changeData"/>
            </div>
        </div>

        <div class="p-col-12 p-md-4">
            <div class="p-text-italic">
                dateouv
            </div>
            <div class="p-inputgroup">
                <InputText id="dateouv" type="text" v-model="value.dateouv" @change="changeData"/>
            </div>
        </div>
    </div>

    <br>
     
    <Button @click="updateData(imputChanged)" label="Save">Save</Button> 
    
  </div>
</template>

<script>

// import {useData} from '../composition/fireconnector'
import store from '../store'
// import { computed }  from 'vue';
import firebase from "firebase";
import {useImager} from '../composition/imager'
import {useModifier} from '../composition/modifier'
import {useFireCon} from '../composition/fireconnector'
import { onMounted } from 'vue';

export default {
    
    setup(props) {
        
        console.log("props", props)
        
        function close () {
            imputChanged = {}
            store.dispatch('closeProductDetail')
        }

        // Methods
        // launch viewer
        function openViewer (val) {
            
            window.open('https://viewer.3dplanner.net/?ref='+val, '_blank');

            // firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(function(idToken) {
            //     // Send token to your backend via HTTPS
            //     // ...
            //     console.log("idToken", idToken)
                
            //     window.open('https://viewer.3dplanner.net/?ref='+val+'&token='+idToken, '_blank');
            //     // document.cookies.set('token', idToken, {
            //     //     domain: window.location.hostname,
            //     //     expire: 1 / 24, // One hour
            //     //     path: '/',
            //     //     secure: true // If served over HTTPS
            //     // });
            // }).catch(function(error) {
            //     // Handle error
            //     console.log("error", error)
            // });
            
        }

        // upload image
        function clickUploadImage () {
            // this.$refs.inputPers.click()  
            var upimg = document.getElementById('inputPers')
            console.log("upimg", upimg)
            upimg.click()  
        }
        function uploadImage (e) {
            console.log("uploadImage", e.target.files[0])
            // window.open('https://viewer.3dplanner.net/?ref='+val, '_blank');
            
            // const {
            //     loading,
            //     error,
            //     data: url,
            //     increment: increment
            // } = useFireConn()

            // this.useFireConn()
            console.log("recupVal.icone", recupVal.icone)
            pushImage(e.target.files[0],'pers',recupVal.icone);
        }

        const {
            // loading,
            // error,
            // data: url,
            pushImage: pushImage
        } = useFireCon()

        
        let imputChanged = {}
        function changeData (e) {
            // recupVal.visibilitebiblio
            
            // imputChanged.push( {e.srcElement.id, 'val': recupVal[e.srcElement.id]} )
            // var foo = {};
            // var bar = 'baz';
            // foo[bar] = '123';
            imputChanged[e.srcElement.id] = recupVal[e.srcElement.id]

            console.log("changeData", imputChanged )
        }

        // function saveProduct () {
            
        //     console.log("saveProduct", recupVal)
        //     //db.ref(modelStore.getters.get_univers+'/collections/'+modelStore.getters.get_collection+'/familles/'+modelStore.getters.get_famille+'/familles2/'+modelStore.getters.get_famille2+'/groupes/'+pos_el).update({
        //     //     visibilitebiblio: this.value
        //     //   })
            
        // }
        
        const recupVal = store.getters.getProductDetail
        onMounted(() => {
            fetchImageUrl(chemin + recupVal.icone, 'img'+recupVal.ref) 
            fetchImageUrl(chemin + recupVal.imagedessus, 'imgTop'+recupVal.ref) 
        })

        const {
            loading,
            error,
            data: url,
            fetchImageUrl: fetchImageUrl
        } = useImager()

        // const {
        //     loading: loading1,
        //     error: error1,
        //     data: url1,
        //     fetchImageUrl: fetchImageUrl1
        // } = useImager()
        // console.log("loading1", loading1)
        // console.log("error1", error1)
        // console.log("url1", url1)
        const {
            loading: loadingChange,
            error: errorChange,
            data: dataChange,
            updateData: updateData
        } = useModifier()

        function getImage (val, r) {
            
            const storage = firebase.storage();
            const storageRef = storage.ref()
            
            let u = ""
            storageRef.child(val).getDownloadURL().then(function(url) {
                // inserted into an <img> element:
                // var card = document.getElementsByClassName('Card');
                var img = document.getElementById(r)
                img.src = url;
                u = url
                return u;
            }).catch(function(error) {
                // Handle any errors
                console.log("error", error)
                u = ""
                return u;
            });
            return u;
        }
        

        // const chemin = "gs://products3dplanner.appspot.com/catalogue/otel/" 
        const chemin = "/catalogue/otel/" 


        return {
            value : recupVal,//store.getters.getProductDetail,
            close,
            getImage,
            chemin,
            loading,
            error,
            url,
            fetchImageUrl,
            openViewer,
            uploadImage,
            clickUploadImage,
            // saveProduct,
            changeData,
            loadingChange,
            errorChange,
            dataChange,
            updateData,
            imputChanged,
            // increment
        }
    
    }

}
</script>

<style scoped>

    .card {
        width: 15rem;
        margin: 0.2em;
        padding: 0.2em;
        display: inline-block;
        border: #CCCCCC;
        border-width: 1px;
        border-style: solid;
    }
    .vignette {
        width: 100%;
        min-width: 250px;
        min-height: 250px;
        max-width: 250px;
        margin: 0.2em;
        padding: 0.2em; 
        border: 2px solid #2196F3;
    }
    .vignette .image {
        width: 100%;
    }

</style>