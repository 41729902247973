<template>

  <Dialog header="Login" v-model:visible="display" :style="{width: '50vw'}" >
    <p>
      Mail :
    <span class="p-float-label ">
        <InputText id="username" type="text" v-model="username_value" style="width: 100%;"/>
        <!-- <label for="username">Username</label> -->
    </span>
      Mot de passe :
    <span class="p-float-label">
        <InputText id="password" type="password" v-model="userpwd_value" style="width: 100%;"/>
        <!-- <label for="password">Password</label> -->
    </span>
    </p>
   
    <template #footer>
        <!-- <Button label="No" icon="pi pi-times" @click="closeBasic" class="p-button-text"/> -->
        <Button label="Connect" icon="pi pi-check" @click="login" autofocus class="p-button-sm" />
    </template>
  </Dialog>

  <div class="login" style="display:none;">
    <h2>Login</h2>
    <input type="text" v-model="email" placeholder="Email"><br>
    <input type="password" v-model="password" placeholder="Password"><br>
    <button @click="login">Sign in</button>
    <p>
        <router-link to="/register">Register</router-link>
    </p>
  </div>


</template>


<script>

import firebase from "firebase";

export default {
   name: 'Login',
   data() {
    return {
      email: "",
      password: "",
      display: true,
      username_value: "",
      userpwd_value: ""
    };
   },
   methods: {
    login: function() {
      // this.$router.replace("home")
      
      firebase
        .auth()
        .signInWithEmailAndPassword(this.username_value, this.userpwd_value)
        .then(
          user => {
            console.log(user, this.email)
            // this.$router.replace("home");
            this.display = false;
          },
          err => {
            alert("Oops. " + err.message);
          }
        );
    }
   }
   
}
</script>

<style scoped>
/* "scoped" attribute limit the CSS to this component only */
.login {
  margin-top: 50px;
}
input {
  width: 40%;
  padding: 20px;
  margin: 20px 0;
}
button {
  width: 10%;
  margin-top: 30px;
  padding: 20px;
  cursor: pointer;
}
p {
  margin-top: 50px;
  font-size: 16px;
}
p a {
  cursor: pointer;
}
</style>