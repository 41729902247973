<template>

    <Breadcrumb :home="home" :model="step" />

    <!-- {{step}} -->
    {{products}}


    <!-- <h2>Les produits</h2>
    <div class="p-text-left">Value: {{ count }}</div> -->
    <!-- History: {{ recentHistory }} -->
    <!-- <Button @click="increment">+</button>
    <Button @click="decrement">-</button><br> -->
    <!-- <Button @click="incrementIfOdd">Increment if odd</button><br>
    <Button @click="incrementAsync">Increment async</button> -->
    
    <!-- {{getDisplayProducts}} -->
    <ProductsList v-if="getDisplayProducts"/>
    <ProductCard v-if="!getDisplayProducts"/>

</template>

<script>

// import ProductsService from '../../service/ProductsService';
import firebase from "firebase";
import { mapGetters, mapActions } from 'vuex'
import ProductsList from '../components/ProductsList'
import ProductCard from '../components/ProductCard'

export default {
  name: 'Products',
  data() {
        return {
            layout: 'grid',
            products: null,
            // pour le Breadcrumb menu
            home: {icon: 'pi pi-home', to: '/'},
            // show: getDisplayProducts,
            // getDisplayProducts: true
            
        }
    },
    // productsService: null,
    // created() {
    //     this.productsService = new ProductsService();
    // },
    mounted() {

        firebase.auth().onIdTokenChanged(function(user) {
            console.log("mounted ", user)
            if (user) {
                // console.log("mounted ", firebase.auth().currentUser );
                // console.log("count", count)
                var products_list = firebase.database().ref("/");
                var tutorials = [];
                products_list.once('value', function(snapshot) {
                    snapshot.forEach(function(childSnapshot) {
                        var key = childSnapshot.key;
                        var data = childSnapshot.val();
                        // ...
                        tutorials.push({ key: key, title: data.title, description: data.description});
                    });
                });
            }
        });


        // this.productsService.getProducts().then(data => this.products = data);
        // console.log("products", this.products.root)
        // this.products = {
        //                     "data": [
        //                         {
        //                             "id": "1000",
        //                             "code": "f230fh0g3",
        //                             "name": "Bamboo Watch",
        //                             "description": "Product Description",
        //                             "image": "bamboo-watch.jpg",
        //                             "price": 65,
        //                             "category": "Accessories",
        //                             "quantity": 24,
        //                             "inventoryStatus": "INSTOCK",
        //                             "rating": 5
        //                         },
        //                         {
        //                             "id": "1001",
        //                             "code": "nvklal433",
        //                             "name": "Black Watch",
        //                             "description": "Product Description",
        //                             "image": "black-watch.jpg",
        //                             "price": 72,
        //                             "category": "Accessories",
        //                             "quantity": 61,
        //                             "inventoryStatus": "INSTOCK",
        //                             "rating": 4
        //                         },
        //                         {
        //                             "id": "1002",
        //                             "code": "zz21cz3c1",
        //                             "name": "Blue Band",
        //                             "description": "Product Description",
        //                             "image": "blue-band.jpg",
        //                             "price": 79,
        //                             "category": "Fitness",
        //                             "quantity": 2,
        //                             "inventoryStatus": "LOWSTOCK",
        //                             "rating": 3
        //                         }
        //                     ]
        //                 }
    },
    created() {


        // firebase.auth().onIdTokenChanged(function(user) {
        //     if (user) {
        //         // User is signed in or token was refreshed.
        //         console.log("hello Seb")
        //         console.log("created", firebase.auth().currentUser);

        //         // console.log("mounted products", firebase.database().ref("/0") );
        //         // console.log("count", count)
        //         var products_list = firebase.database().ref("/");
        //         var tutorials = [];
        //         products_list.once('value', function(snapshot) {
        //             snapshot.forEach(function(childSnapshot) {
        //                 var key = childSnapshot.key;
        //                 var data = childSnapshot.val();
        //                 // ...
        //                 tutorials.push({ key: key, title: data.title, description: data.description});
        //             });
        //         });
        //     }
        // });

        // var storageRef = firebase.storage().ref();
        // var pathReference = storage.ref('images/stars.jpg');
        // console.log("storageRef", storageRef)
        
        // this.$store.dispatch('products/getAllProducts')

        // console.log("hello Seb")
        // console.log("created", firebase.auth().currentUser);
        
        // // console.log("mounted products", firebase.database().ref("/0") );
        // // console.log("count", count)


        var products_list = firebase.database().ref("/");
        var tutorials = [];
        products_list.once('value', function(snapshot) {
            snapshot.forEach(function(childSnapshot) {
                var key = childSnapshot.key;
                var data = childSnapshot.val();
                // ...
                tutorials.push({ key: key, title: data.title, description: data.description});
            });
        });
        
        // console.log("getProducts", this.$store.dispatch('getProducts'))
        

    },
    computed: mapGetters([
        'count',
        'recentHistory',
        'step',
        'getDisplayProducts'
        // 'getProducts'
    ]),
    methods: mapActions([
        'increment',
        'decrement',
        'incrementIfOdd',
        'incrementAsync'
    ]),
    components : {
        ProductsList,
        ProductCard
    }
}
</script>