// import { reactive, ref } from "vue";
import { reactive, toRefs } from "vue";
import axios from 'axios';
import store from '../store'

export function useFetcher() {
    
    console.log("useFetcher")

    const state = reactive({
        loading: false,
        data: [],
        error: false
    })
    // const loading = ref(false)
    // const data = ref([])
    // const error = ref(false)
    // // const fetchData = async function () {
    // // const fetchData = async function () {
    // //     loading.value = true
    // //     console.log("_=========__")
    // //     return axios.get('https://products3dplanner.firebaseio.com/1/collections/O_0/fammilles/0/groupes.json').then(res => {
    // //         // console.log("_=========__", res)
    // //         loading.value = false
    // //         data.value = res
    // //         // console.log("data.value", data.value)
    // //         error.value = true
    // //     })
    // // }
    const fetchData = async function () {
        // loading.value = true
        // data.value = ["hello","buy"]
        // error.value = false;
        // loading.value = false
        state.loading = true
        state.data = ["hello","buy"]
        // return await axios.get('https://my3dplanner.firebaseio.com/1/collections/0/familles/0/groupes.json').then(res => {
        return await axios.get('https://'+store.getters.getProject+'.firebaseio.com/1/collections/0/familles/0/groupes.json').then(res => {
            // console.log("_=========__", res)
            console.log("res", res)
            // loading.value = false
            state.data = res.data
            // console.log("data.value", data.value)
            // error.value = true
            state.error = false
            state.loading = false

        })
    }
    
    // console.log("fetchData", fetchData)
    // console.log("state", state)
    // console.log("state", toRefs(state));

    // return [
    //     loading,
    //     data,
    //     error,
    //     fetchData
    // ]
    return {...toRefs(state), fetchData}
}