export const count = state => state.count

export const recentHistory = state => {
  return state.history
    .slice(-5)
    .join(', ')
}


export const step = state => {

    // // var tab = []//state.step//.split(':');
    // // if ( state.step != '' ) {

    // //   console.log("tab", tab)
    // //   console.log("tab", tab.split(':'))
    // // }
    return state.step
    // // return state.products[0].nom_fr
    // // return tab[0]

    // var final_val = []
    // var tab = val.toString().split(':')
    // // state.step = tab;
    // for ( var i = 0; i < tab.length; i++ ) {
    //     var ori;
    //     if ( i == 0 ) {
    //         ori = state.products[tab[i]]
    //         final_val.push({label: ori.nom_fr})
    //     }else if ( i == 1 ) {
    //         ori = ori.collections[tab[i]]
    //         final_val.push({label: ori.nom_fr})
    //     }else if ( i == 2 ) {
    //         ori = ori.familles[tab[i]]
    //         final_val.push({label: ori.nom_fr})
    //     }else if ( i == 3 ) {
    //         ori = ori.familles2[tab[i]]
    //         final_val.push({label: ori.nom_fr})
    //     }else if ( i == 4 ) {
    //         ori = ori.familles3[tab[i]]
    //         final_val.push({label: ori.nom_fr})
    //     }
    // }
    
    // state.step = final_val

}

export const getCurrentStep = state => {
  return state.currentStep
}

export const getCurrentProduct = state => {
  return state.currentProduct
}

export const getProducts = state => {
  return state.products
}

export const getGroupes = state => {
  console.log("getGroupes", state.groupes )
  return state.groupes
}
export const getDisplayProducts = state => {
  // console.log("getGroupes", state.groupes )
  return state.displayProducts
}

export const getProductDetail = state => {
  // console.log("getGroupes", state.groupes )
  return state.productdetail
}

export const getProject = state => {
  // console.log("getGroupes", state.groupes )
  return state.currentProject
}