<template>
    <!-- n°  {{n}}
    <Button @click="increment">+</Button> -->
  <div id="productsList" :model="groupes" v-bind="init()"> 
    <!-- <div v-for="product in groupes" :key="product.ref"> -->
        
        
        <div class="card" v-for="product in groupes" :key="product.ref" >
            n° {{ idproduct++ }}
            <!-- <img id="img" v-bind:src="chemin + product.icone"> -->
            <img class="vignette" :id="'img'+product.ref" v-bind="getImage(chemin + product.icone, 'img'+product.ref)" width="100%">
            <!-- :{{ (chemin + product.icone) }} -->
            <!-- <img id="img" src=""> -->
            <span> nom : {{ product.nom_fr }} </span>
            <br>
            <span> ref : {{ product.ref_devis }} </span>
            <br>
            <!-- <span> img : {{ product.icone }} </span> -->
            
            <br>
            <Button icon="pi pi-eye" label="" name="afficher" :data-id="idproduct" class="p-button-sm" @click="detailProduct($event,product)"></Button>
            <Button icon="pi pi-trash" label="" name="supprimer" class="p-button-sm" ></Button>
            <Button icon="pi pi-clone" label="" name="dupliquer" class="p-button-sm" ></Button>
        </div>
        <div class="card">
            
            <Button icon="pi pi-check" label="nouveau" class="p-button-sm" ></Button>
        </div>


    <!-- </div> -->
  </div>
  
  <!-- <button @click="fetchProducts">fetch products</button> -->

</template>

<script>

import {useFetcher} from '../composition/fetcher'
import store from '../store'
import { computed }  from 'vue';
import firebase from "firebase";

export default {
    // name: 'productsList',
    //   setup(props, context) {
    //       console.log(props, context)
    //         const [n, increment] = useIncrement()
    //         return {
    //             n: n,
    //             increment
    //         }
    // }

    

    setup(props) {

        console.log("props", props)
        
        

        // storageRef.child('images/stars.jpg').getDownloadURL().then(function(url) {
        //     // `url` is the download URL for 'images/stars.jpg'

        //     // This can be downloaded directly:
        //     var xhr = new XMLHttpRequest();
        //     xhr.responseType = 'blob';
        //     xhr.onload = function(event) {
        //         var blob = xhr.response;
        //     };
        //     xhr.open('GET', url);
        //     xhr.send();

        //     // Or inserted into an <img> element:
        //     var img = document.getElementById('myimg');
        //     img.src = url;
        //     }).catch(function(error) {
        //     // Handle any errors
        //     });

        function detailProduct (e, detail, id) {
            console.log("detailProduct", detail)
            console.log("idProduct", id)
            console.log('ID:', e.currentTarget.getAttribute('data-id'));
            // store.getters.getGroupes
            store.dispatch('showProductDetail', [detail, e.currentTarget.getAttribute('data-id')])
        }

        function getImage (val, r) {
            

            const storage = firebase.storage();
            const storageRef = storage.ref()
            // const pathReference = storage.ref('catalogue/otel/16ded9ccd4d-pers.png');
            // const pathReference = storage.ref(val);
            // console.log("pathReference", pathReference)
            
            let u = ""
            storageRef.child(val).getDownloadURL().then(function(url) {
                // inserted into an <img> element:
                // var card = document.getElementsByClassName('Card');
                var img = document.getElementById(r)
                
                console.log("image", url)
                img.src = url;
                u = url
                return u;
            }).catch(function(error) {
            // Handle any errors
                console.log("error", error)
                u = ""
                return u;
            });
            return u;
        }
        

        


        let idproduct = 0
        function init () {            
            this.idproduct = 0
        }

        // onUpdated(() => {
        //     console.log('updated!')
        //     this.idproduct = 0
        // })


        // const chemin = "gs://products3dplanner.appspot.com/catalogue/otel/" 
        const chemin = "/catalogue/otel/" 
        const {
            loading,
            error,
            data: products,
            fetchData: fetchProducts
        } = useFetcher()
        console.log("error", error)
        
        // idproduct = onUpdated(() => {return 0} );
        return {
            loading,
            error,
            products,
            idproduct,
            groupes: computed(() => store.getters.getGroupes),
            fetchProducts,// : () => { console.log("hello") }
            chemin,
            getImage,
            detailProduct,
            init
        }
    }

    
    // computed: {
    // // mix the getters into computed with object spread operator
    //     ...mapGetters(this.$store.groupes)
    // }

    

    // useEventBusListener(MY_EVENT, handleMyEvent)
    // useActivityTracker()
    // useReloadBlocker(context)
    // useLocale(context)

    // const isAuthenticated = computed(() => ...)

    // watch(() => {
    //   if (!isAuthenticated) {}
    // })

    // function handleMyEvent() {},

    // function useLocale() {}
    // function useActivityTracker() {}
    // function useEventBusListener() {}
    // function useReloadBlocker() {}

    
//   }
}
</script>

<style scoped>
    .card {
        width: 15rem;
        margin: 0.2em;
        padding: 0.2em;
        display: inline-block;
        border: #EEEEEE;
        border-width: 1px;
        border-style: solid;
    }
    .card .vignette {
        width: 100%;

        /* border: #CCCCCC;
        border-width: 1px;
        border-style: solid; */
        /* margin: 0.2em;
        padding: 0.2em; */
        min-width: 232px;
        min-height: 232px;
        outline:1px solid #DDDDDD;

        
    }
</style>